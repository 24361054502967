
import { defineComponent, onMounted, onBeforeMount } from "vue";
import ToolbarFilter from "@/components/toolbar/ToolbarFilter.vue";
import ToolbarActions from "@/components/toolbar/ToolbarActions.vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import useComponentv2 from '@/composables/v2/useComponent';
import Table from '@/components/tables/v2/Table.vue';
import ModalTable from "@/components/modals/default/v2/ModalTable.vue";
import Icon from '@/components/icons/Icon.vue';

export default defineComponent({
  name: '',
  components: {
    ToolbarFilter,
    ToolbarActions,
    Table,
    Icon,
    ModalTable,
  },
    props: {
      currentId: String
    },
    setup (props, { emit }) {
      
      const { initComponent, componentData, componentRegisterId, componentLoading, updateComponent, submitButtonComponent, submitLoadingComponent, saveComponent, componentStatus, reloadComponent, test, renderModal, renderAmount, renderId } = useComponentv2();

      initComponent({
        componentName: "ManagementAccountInvoicesTable", 
        displayMethod: "existing", /* new or existing */
        componentType: "table", /* form or table */
        dispatchActions: { init: "ACCOUNT_INVOICES", save: "", params: { init: false, key: "", value: ""} },
        componentReload: true,
        componentRedirect: { init: false, data: "", path: ""},
        currentData: "allAccountInvoices",
        componentFilter: { init: false, get: "currentAccountSubscriptionsFilter" },
        componentToolbar: { init: true, filter: true, actions: { init: true, title: "Invoices", menu: [  ] } },
        currentId: props.currentId
      });

      onMounted(() => {
        MenuComponent.reinitialization();
      })

    return {
      componentData,
      componentRegisterId,
      componentLoading,
      componentStatus,
      updateComponent,
      submitButtonComponent,
      submitLoadingComponent,
      saveComponent,
      reloadComponent,
      test,
      renderModal,
      renderId,
      renderAmount
    }
  }
});
